import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationDetails, CognitoUser, CognitoUserPool, CognitoUserSession, CognitoRefreshToken } from 'amazon-cognito-identity-js';

import { AppStoreUtils } from 'app/store/app.store';
import { ClearAllStoresAction } from 'app/store/app.store.constants';
import * as actions from 'app/store/actions';
import { environment } from 'environments/environment';


@Injectable({
  providedIn: 'root',
})
export class CognitoService {
    public static _REGION = environment.region;
    public static _USER_POOL_ID = environment.userPoolId;
    public static _CLIENT_ID = environment.clientId;

    public static _POOL_DATA: any = {
        UserPoolId: CognitoService._USER_POOL_ID,
        ClientId: CognitoService._CLIENT_ID
    };

    constructor(private router: Router, private store: AppStoreUtils) { }

    authenticate(username: string, password: string, callback: any) {
        const authenticationDetails = this.getAuthenticationDetails(username, password);
        const cognitoUser = this.getCognitoUser(username);

        cognitoUser.authenticateUser(authenticationDetails, {
            newPasswordRequired: (userAttributes, requiredAttributes) => {
                console.log(userAttributes, requiredAttributes);
                callback('newPasswordRequired');
            },
            onSuccess: (result: CognitoUserSession) => {
                localStorage.setItem('jwt-token', result.getIdToken().getJwtToken());
                localStorage.setItem('jwt-refresh', result.getRefreshToken().getToken());
                this.store.dispatch(new actions.login.LoginSuccess(result.getIdToken().getJwtToken()));
                this.router.navigate(environment.postLoginPage);
            },
            onFailure: (error) => {
                if (['UserNotFoundException', 'NotAuthorizedException'.includes(error.code)]) {
                    this.store.dispatch(new actions.login.LoginFail(['Incorrect username or password']));
                } else {
                    console.error('CognitoService: cannot login');
                    this.store.dispatch(new actions.login.LoginFail(['An unknown error has occured. Please contact OpenGoSim.']));
                }
            },
        });
    }

    forgotPassword(username: string, callback: any) {
        const cognitoUser = this.getCognitoUser(username);
        cognitoUser.forgotPassword({
            onSuccess: (r) => console.log('onSuccess', r),
            onFailure: (r) => console.log('onFailure', r),
            inputVerificationCode: (r) => callback('inputVerificationCode'),
        });
    }

    getAuthenticationDetails(username: string, password: string): AuthenticationDetails {
        const authenticationData = {
            Username: username,
            Password: password,
        };
        return new AuthenticationDetails(authenticationData);
    }

    getCognitoUser(username: string): CognitoUser {
        const userData = {
            Username: username,
            Pool: this.getUserPool(),
        };
        return new CognitoUser(userData);
    }

    getUserPool() {
        return new CognitoUserPool(CognitoService._POOL_DATA);
    }

    getCurrentUser() {
        return this.getUserPool().getCurrentUser();
    }

    newPassword(username: string, password: string, newPassword: string): void {
        const authenticationDetails = this.getAuthenticationDetails(username, password);
        const cognitoUser = this.getCognitoUser(username);

        cognitoUser.authenticateUser(authenticationDetails, {
            newPasswordRequired: (userAttributes, requiredAttributes) =>
                cognitoUser.completeNewPasswordChallenge(newPassword, requiredAttributes, {
                    onFailure: (err) => console.log(`CognitoService: Error setting new password:`, err),
                    onSuccess: (result) => {
                        console.log('yay', result);
                        this.store.dispatch(new actions.login.LoginSuccess(result.getIdToken().getJwtToken()));
                        setTimeout(() => this.router.navigate(environment.postLoginPage), 500);
                    }
                }
            ),
            onFailure: () => null,
            onSuccess: () => null,
        });
    }

    newPasswordWithCode(username: string, verificationCode: string, newPassword: string) {
        const cognitoUser = this.getCognitoUser(username);
        cognitoUser.confirmPassword(verificationCode, newPassword, {
            onFailure: (err) => console.log(`CognitoService: Error setting new password:`, err),
            onSuccess: () => this.authenticate(username, newPassword, null)
        });
    }

    refresh(): void {
        try {
            const cognitoToken: CognitoRefreshToken = new CognitoRefreshToken({ RefreshToken: localStorage.getItem('jwt-refresh') });
            this.getCurrentUser().refreshSession(cognitoToken, (err, session) => {
                if (err) {
                    console.error('CognitoService: Can\'t set the credentials:' + err);
                    this.router.navigate(['/login']);
                } else {
                    if (session.isValid()) {
                        localStorage.setItem('jwt-token', session.getIdToken().getJwtToken());
                        this.store.dispatch(new actions.login.LoginSuccess(session.getIdToken().getJwtToken()));
                    } else {
                        console.error('CognitoService: refreshed but session is still not valid');
                    }
                }
            });
        } catch (err) {
            localStorage.removeItem('jwt-token');
            localStorage.removeItem('jwt-refresh');
            this.store.dispatch(new actions.login.Logout());
            this.store.dispatch(new ClearAllStoresAction());
            this.router.navigate(['/login']);
        }
    }

}
